import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table, Input, message, Button, Form, Select, Modal } from 'antd';
import { cloneDeep } from "lodash"
import moment from "moment";
import { SVRS, MODEL_TYPES } from '../../utils/define'
import { render } from "@testing-library/react";

const Index = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState([])
  const [update, setUpdate] = useState(new Date().getTime())
  const [tempTxt, setTempTxt] = useState('')
  const [open, setOpen] = useState(false)
  const n = useNavigate()
  const [search, setSearch] = useState({
    page: 1,
    page_size: 20
  })
  const curData = useRef()

  const loadData = async (s) => {
    try {
      let data = await http.post(`${apis.LLM_CHANNEL_LIST}`, s)
      setResponse(data)
    } catch (error) {

    }
  }

  const updateContent = async (obj) => {
    try {
      await http.post(apis.TEMPLATE_UPDATE, obj)
    } catch (error) {

    }
  }

  useEffect(() => {
    setLoading(true)

    loadData(search).then(() => {
      setLoading(false)
    })

    let onresize = () => {
      setUpdate(new Date().getTime())
    }

    window.addEventListener("resize", onresize)
    return () => {
      window.removeEventListener("resize", onresize)
    }

    // eslint-disable-next-line
  }, [])


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      fixed: 'left',
      width: 30
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 120
    },
    {
      title: '服务商',
      dataIndex: 'svr',
      width: 80
    },
    {
      title: '模型类型',
      dataIndex: 'model_type',
      width: 80,
      render: (v) => (
        <>{
          MODEL_TYPES.find(i => i.value === v).label
        }</>
      )
    },
    {
      title: '模型',
      dataIndex: 'model',
      width: 80
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      width: 80,
      render: (v) => (
        <>
          {moment(v).format("YYYY-MM-DD HH:mm:ss")}
        </>
      )
    },
    {
      title: '操作',
      dataIndex: 'ac',
      fixed: 'right',
      width: 80,
      render: (v, r) => (
        <div>
          <Button type="link" onClick={() => {
            sessionStorage.setItem(`channel${r.id}`, JSON.stringify(r))
            n(`/llm/channel/create?id=${r.id}`)
          }}>修改</Button>
          <Button type="link" onClick={async () => {
            setLoading(true)
            try {
              await http.post(`${apis.LLM_CHANNEL_PING}?id=${r.id}`)
              message.success("成功")
            } catch (error) {

            }
            setLoading(false)
          }}>测试</Button>
          <Button danger type="link" onClick={async () => {
            Modal.confirm({
              title: "提示",
              content: "确定删除吗?",
              onOk: async () => {
                try {
                  await http.post(`${apis.LLM_CHANNEL_DELETE}?id=${r.id}`, {})
                  await loadData()
                  message.success("成功")
                } catch (error) {

                }
              }
            })
          }}>删除</Button>
        </div>
      )
    }
  ];

  return (
    <div>
      <span style={{ display: "none" }}>{update}</span>
      <Form autoComplete="off" layout="inline" onFinish={async (val) => {
        const cpSearch = cloneDeep(search)
        cpSearch.page = 1
        cpSearch.svr = val.svr
        cpSearch.model_type = val.model_type
        setSearch(cpSearch)
        setLoading(true)
        await loadData(cpSearch)
        setLoading(false)
      }}>
        <Form.Item name="svr">
          <Select style={{ width: 200 }} options={SVRS} allowClear placeholder="服务商" />
        </Form.Item>
        <Form.Item name="model_type">
          <Select style={{ width: 200 }} options={MODEL_TYPES} allowClear placeholder="类型" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">查询</Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => {
            n(`/llm/channel/create`)
          }}>新增</Button>
        </Form.Item>
      </Form>
      <div style={{ height: 24 }}></div>
      <Table
        scroll={{
          x: "100vw",
          y: `calc(100vh - 350px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
        }}
        rowKey={`id`}
        columns={columns}
        loading={loading}
        dataSource={response || []} />
    </div>
  )
}

export default Index