import { values } from "lodash"

export const SVRS = [
  {
    label: "Azure OpenAI",
    value: "Azure OpenAI"
  }, {
    label: "OpenAI",
    value: "OpenAI"
  }, {
    label: "Aws AI",
    value: "Aws AI"
  }, {
    label: "Google AI",
    value: "Google AI"
  }
]

export const MODEL_TYPES = [
  {
    label: "文本",
    value: "TXT"
  }, {
    label: "语音",
    value: "VOICE"
  }, {
    label: "图片",
    value: "IMAGE"
  }
]

export const TTS_NAMES = {
  "Azure OpenAI": [
    {
      value: "en-US-AvaMultilingualNeural",
      label: "en-US-AvaMultilingualNeural"
    },
    {
      value: "en-US-AndrewMultilingualNeural",
      label: "en-US-AndrewMultilingualNeural"
    },
    {
      value: "en-US-EmmaMultilingualNeural",
      label: "en-US-EmmaMultilingualNeural"
    },
    {
      value: "en-US-BrianMultilingualNeural",
      label: "en-US-BrianMultilingualNeural"
    }
  ],
  "Google AI": [
    {
      value: "en-US-Neural2-A",
      label: "谷歌-en-US-Neural2-A"
    },
    {
      value: "en-US-Neural2-C",
      label: "谷歌-en-US-Neural2-C"
    },
    {
      value: "en-US-Neural2-D",
      label: "谷歌-en-US-Neural2-D"
    },
    {
      value: "en-US-Neural2-E",
      label: "谷歌-en-US-Neural2-E"
    }
  ],
  "OpenAI":
    [
      {
        value: "onyx",
        label: "onyx"
      },
      {
        value: "alloy",
        label: "alloy"
      },
      {
        value: "echo",
        label: "echo"
      },
      {
        value: "fable",
        label: "fable"
      }, {
        value: "nova",
        label: "nova"
      },
      {
        value: "shimmer",
        label: "shimmer"
      }
    ]
}

export const WORKFLOW_TYPES = [
  {
    label: "bing文本搜索",
    value: "BING_SEARCH"
  },
  {
    label: "bing图片搜索",
    value: "BING_IMAGE"
  },
  {
    label: "tavily文本搜索",
    value: "TAVILY_SEARCH"
  },
  {
    label: "tavily图片搜索",
    value: "TAVILY_IMAGE"
  },
  {
    label: "大模型",
    value: "LLM"
  },
  {
    label: "大模型画图",
    value: "LLM_IMAGE"
  }
]

export const FUNS = {
  "CHAT": "聊天",
  "ARTICLE": "生成文章",
  "WORK_CARD": "文章词卡",
  "QUIZ_CARD": "QUIZ",
  "SEARCH_SUMMARY": "搜索总结",
  "RELETED": "搜索releted",
  "VIDEO_SUMMARY": "视频总结",
  "ARTICLE_IMG": "文章封面提示词",
  "QUIZ_PARSE": "QUIZ答错解析",
  "TTS_GENERAL": "TTS（通用场景）",
  "TTS_ARTICLE": "TTS（文章场景）",
  "TTS_WORD_CARD": "TTS（单词卡片）",
  "IMAGE_ARTICLE": "文生图（文章封面）",
  "WORKFLOW_ARTICLE_1": "文章工作流节点-1",
  "WORKFLOW_ARTICLE_2": "文章工作流节点-2",
  "WORKFLOW_ARTICLE_3": "文章工作流节点-3",
  "WORKFLOW_ARTICLE_4": "文章工作流节点-4"
}

