import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table, Input, message, Button, Form, Select, Modal } from 'antd';
import { cloneDeep } from "lodash"
import moment from "moment";
import { sleep } from '../../utils'
import { render } from "@testing-library/react";

const Vars = (props) => {
  const { value = {}, onChange } = props

  const forin = () => {
    const arr = []
    for (let i in value) {
      arr.push({
        key: i,
        value: value[i]
      })
    }
    return arr
  }

  const forind = (arr) => {
    const obj = {}
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i].key] = arr[i].value
    }
    return obj
  }

  const arr = forin()

  return (
    <div style={{}}>
      {
        arr.map((v, k) => (
          <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }} key={k}>
            <span><Input value={v.key} onChange={e => {
              const cpV = cloneDeep(arr)
              cpV[k].key = e.target.value
              onChange(forind(cpV))
            }} /></span> : <span><Input.TextArea value={v.value} onChange={e => {
              const cpV = cloneDeep(arr)
              cpV[k].value = e.target.value
              onChange(forind(cpV))
            }} /></span>
          </div>
        ))
      }
      <Button onClick={() => {
        const cpV = cloneDeep(arr)
        cpV.push({
          key: "",
          value: ""
        })
        onChange(forind(cpV))
      }}>添加变量</Button>
    </div>
  )
}

const Index = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState([])
  const [form] = Form.useForm()
  const [update, setUpdate] = useState(new Date().getTime())
  const curRecord = useRef()
  const [open, setOpen] = useState(false)
  const n = useNavigate()
  const [testResult, setTestResult] = useState()
  const [search, setSearch] = useState({
    page: 1,
    page_size: 20
  })
  const curData = useRef()

  const loadData = async (s) => {
    try {
      let data = await http.get(`${apis.LLM_WORKFLOW_LIST}`, s)
      for (let i = 0; i < data.length; i++) {
        data[i].vars = JSON.parse(data[i].vars)
      }
      setResponse(data)
    } catch (error) {

    }
  }

  const updateContent = async (obj) => {
    try {
      await http.post(apis.TEMPLATE_UPDATE, obj)
    } catch (error) {

    }
  }

  useEffect(() => {
    setLoading(true)

    loadData(search).then(() => {
      setLoading(false)
    })

    let onresize = () => {
      setUpdate(new Date().getTime())
    }

    window.addEventListener("resize", onresize)
    return () => {
      window.removeEventListener("resize", onresize)
    }

    // eslint-disable-next-line
  }, [])


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      fixed: 'left',
      width: 30
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 30
    },
    // {
    //   title: '备注',
    //   dataIndex: 'remark',
    //   width: 120
    // },
    {
      title: '操作',
      dataIndex: 'ac',
      fixed: 'right',
      width: 80,
      render: (v, r) => (
        <div>
          <Button type="link" onClick={() => {
            sessionStorage.setItem(`llmworkflow${r.id}`, JSON.stringify(r))
            n(`/llm/workflow/edit?id=${r.id}`)
          }}>修改</Button>
          <Button type="link" onClick={async () => {
            curRecord.current = r
            const obj = {}
            r.vars = r.vars || []
            for (let i = 0; i < r.vars.length; i++) {
              obj[r.vars[i].key] = ""
            }
            form.setFieldValue("vars", obj)
            setOpen(true)
          }}>测试</Button>
        </div>
      )
    }
  ];

  return (
    <div>
      <span style={{ display: "none" }}>{update}</span>
      <Modal
        width={`80vw`}
        title="测试工作流"
        okButtonProps={{ loading }}
        open={open}
        forceRender
        onCancel={() => setOpen(false)}
        onOk={async () => {
          setLoading(true)
          try {
            setTestResult(null)
            const values = await form.validateFields()
            console.log("values", values.vars, curRecord.current)
            const flag = await http.post(apis.LLM_WORKFLOW_RUN, {
              id: curRecord.current.id,
              vars: values.vars
            })
            const aloop = async (f) => {
              const result = await http.get(`${apis.LLM_WORKFLOW_RUN_RESULT}?key=${f}`)
              if (!result) {
                await sleep(1000)
                await aloop(f)
              } else {
                const resultJson = JSON.parse(result)
                console.log(resultJson)
                setTestResult(resultJson)
                if (resultJson.step !== "2") {
                  await sleep(1000)
                  await aloop(f)
                }
              }
            }
            await aloop(flag)
            console.log(flag)
          } catch (error) {
            console.log(error)
          }
          setLoading(false)
        }}
      >
        <div style={{ height: "70vh", overflow: "auto" }}>
          <Form form={form}>
            <Form.Item name="vars" label="变量">
              <Vars />
            </Form.Item>
          </Form>
          {
            testResult && <div>
              <h3>测试结果：</h3>
              {
                testResult.requests.map((v, k) => (
                  <div key={k}>
                    <h4>step{k + 1}</h4>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <span>输入：</span>
                        <span>
                          <Input.TextArea value={v} />
                        </span>
                      </div>
                      <div style={{ flex: 1 }}>
                        <span>输出：{(testResult.times[k] / 1000).toFixed(2)}秒</span>
                        <span>
                          <Input.TextArea value={testResult.responses[k]} />
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          }
        </div>
      </Modal>
      <Button type="primary" onClick={async () => {
        setLoading(true)
        try {
          await http.post(apis.LLM_WORKFLOW_CREATE, {})
          await loadData()
          message.success("成功")
        } catch (error) {

        }
        setLoading(false)
      }}>新增</Button>
      <div style={{ height: 12 }}></div>
      <Table
        scroll={{
          // x: "100vw",
          y: `calc(100vh - 350px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
        }}
        rowKey={`id`}
        columns={columns}
        loading={loading}
        dataSource={response || []}
        pagination={false}
      />
    </div>
  )
}

export default Index