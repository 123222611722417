
import { Form, Input, Spin, Button, Select, Modal, message } from "antd"
import { useEffect, useState } from "react"
import { SVRS, TTS_NAMES, MODEL_TYPES } from "../../utils/define"
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { useNavigate } from "react-router-dom"
const { MODELS } = window

const Index = () => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const svr = Form.useWatch('svr', form)
  const modelType = Form.useWatch('model_type', form)
  const n = useNavigate()
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const param = new URLSearchParams(window.location.search)
    if (param.get("id")) {
      setDisabled(true)
      const data = JSON.parse(sessionStorage.getItem(`channel${param.get("id")}`))
      form.setFieldsValue(data)
    }

  }, [])

  const onSubmit = async (values) => {
    setLoading(true)
    if (values.id) {
      console.log(values)
      try {
        await http.post(apis.LLM_CHANNEL_UPDATE, values)
        message.success("成功")
        n("/llm/channel")
      } catch (error) {

      }
    } else {

      try {
        await http.post(apis.LLM_CHANNEL_CREATE, values)
        message.success("成功")
        n("/llm/channel")
      } catch (error) {

      }
    }
    setLoading(false)
  }

  return (
    <div style={{ height: "calc(100vh - 227px)", overflow: "auto" }}>
      <Spin spinning={loading}>
        <Form form={form} autoComplete="off" onFinish={onSubmit}>
          <Form.Item name="id" hidden>
            <Input placeholder="请填写" />
          </Form.Item>
          <Form.Item name="model_type" hidden label="名称">
            <Input placeholder="请填写" />
          </Form.Item>
          <Form.Item name="name" label="名称">
            <Input placeholder="请填写" />
          </Form.Item>
          <Form.Item name="svr" label="服务商" initialValue={SVRS[0].value.toString()}>
            <Select options={SVRS} placeholder="请选择"></Select>
          </Form.Item>
          <Form.Item name="model_type" label="类型" initialValue={MODEL_TYPES[0].value.toString()}>
            <Select disabled={disabled} options={MODEL_TYPES} placeholder="请选择"></Select>
          </Form.Item>
          {
            modelType === "TXT" && svr === "Google AI" &&
            <>
              <Form.Item name="project_id" label="projectID">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="region" label="区域">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="model" label="模型">
                <Select options={svr ? MODELS[svr] : []} allowClear placeholder="请选择"></Select>
              </Form.Item>
              <Form.Item name="credentials_json" label="密钥JSON">
                <Input.TextArea rows={20} placeholder="请填写" />
              </Form.Item>
            </>
          }
          {
            modelType === "TXT" && svr === "Aws AI" &&
            <>
              <Form.Item name="api_key" label="api_key">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="api_secret" label="api_secret">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="region" label="区域">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="model" label="模型">
                <Select options={svr ? MODELS[svr] : []} allowClear placeholder="请选择"></Select>
              </Form.Item>
            </>
          }
          {
            modelType === "TXT" && svr === "Azure OpenAI" &&
            <>
              <Form.Item name="api_key" label="api_key">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="base_url" label="终结点">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="dev_name" label="部署名">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="model" label="模型">
                <Select options={svr ? MODELS[svr] : []} allowClear placeholder="请选择"></Select>
              </Form.Item>
            </>
          }
          {
            modelType === "TXT" && svr === "OpenAI" &&
            <>
              <Form.Item name="api_key" label="api_key">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="model" label="模型">
                <Select options={svr ? MODELS[svr] : []} allowClear placeholder="请选择"></Select>
              </Form.Item>
            </>
          }
          {
            modelType === "VOICE" && svr === "OpenAI" &&
            <>
              <Form.Item name="api_key" label="api_key">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="tts_name" label="TTS角色">
                <Select placeholder="请选择" options={svr ? TTS_NAMES[svr] : []} />
              </Form.Item>
            </>
          }
          {
            modelType === "VOICE" && svr === "Google AI" &&
            <>
              <Form.Item name="tts_name" label="TTS角色">
                <Select placeholder="请选择" options={svr ? TTS_NAMES[svr] : []} />
              </Form.Item>
              <Form.Item name="credentials_json" label="密钥JSON">
                <Input.TextArea rows={20} placeholder="请填写" />
              </Form.Item>
            </>
          }
          {
            modelType === "VOICE" && svr === "Azure OpenAI" &&
            <>
              <Form.Item name="tts_name" label="TTS角色">
                <Select placeholder="请选择" options={svr ? TTS_NAMES[svr] : []} />
              </Form.Item>
              <Form.Item name="api_key" label="api_key">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="base_url" label="终结点">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="region" label="区域">
                <Input placeholder="请填写" />
              </Form.Item>
            </>
          }
          {
            modelType === "IMAGE" && svr === "OpenAI" &&
            <>
              <Form.Item name="api_key" label="api_key">
                <Input placeholder="请填写" />
              </Form.Item>
            </>
          }
          {
            modelType === "IMAGE" && svr === "Azure OpenAI" &&
            <>
              <Form.Item name="api_key" label="api_key">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item name="base_url" label="终结点">
                <Input placeholder="请填写" />
              </Form.Item>
              <Form.Item initialValue="dall-e-3" name="model" label="模型">
                <Input />
              </Form.Item>
              <Form.Item initialValue="Dalle3" name="dev_name" label="部署名">
                <Input placeholder="请填写" />
              </Form.Item>
            </>
          }
          <Form.Item>
            <Button type="primary" htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  )
}

export default Index