import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table, Input, message, Button, Form, Select, Modal, Spin, Tag, InputNumber } from 'antd';
import { cloneDeep } from "lodash"
import moment from "moment";
import { SVRS, MODEL_TYPES } from '../../utils/define'
import { render } from "@testing-library/react";
import { FUNS, WORKFLOW_TYPES } from '../../utils/define'

const VarInputTextArea = (props) => {
  let { vars = [], index = 0, ...others } = props
  const putRef = useRef()
  const steps = []
  for (let i = 0; i < index; i++) {
    steps.push({
      name: `step${i + 1}的结果`,
      key: `{step${i + 1}}`
    })
  }

  vars = [...vars, ...steps]

  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        {
          vars.map((v, k) => (
            <Tag style={{ cursor: "pointer" }} key={k} onClick={() => {

              // 获取文本区域的元素
              var textarea = putRef.current.resizableTextArea.textArea

              // 获取光标相对于文本区域的位置
              var start = textarea.selectionStart;
              var end = textarea.selectionEnd;

              // 获取要插入的值
              var newValue = v.key;

              // 在光标位置的后面插入新值
              var oldValue = textarea.value;
              var newString = oldValue.substring(0, start) + newValue + oldValue.substring(end, oldValue.length);

              // 将新值设置回文本区域
              textarea.value = newString;

              // 设置光标位置为插入后的位置
              textarea.selectionStart = start + newValue.length;
              textarea.selectionEnd = start + newValue.length;

              // 将焦点设置回文本区域
              textarea.focus();

              // putRef.current.resizableTextArea.textArea.value += v.key
              // console.log(putRef.current)
              props.onChange({ target: textarea })
            }}>{v.name}</Tag>
          ))
        }
      </div>
      <Input.TextArea
        ref={putRef}
        {
        ...others
        }
      />
    </div>
  )
}

const Item = (props) => {
  const { value = [], vars = [], onChange, channels } = props
  return (
    <div style={{ marginTop: -14 }}>
      {
        value.map((v, k) => (
          <div key={k}>
            <h3>step{k + 1} <Button onClick={() => {
              const cpValue = cloneDeep(value)
              cpValue.splice(k, 1)
              onChange(cpValue)
            }} type="link" danger>删除</Button></h3>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
              <span>名称：</span>
              <span><Input value={v.name} onChange={e => {
                const cpValue = cloneDeep(value)
                cpValue[k].name = e.target.value
                onChange(cpValue)
              }} /></span>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
              <span>类型：</span>
              <span>
                <Select onChange={val => {
                  const cpValue = cloneDeep(value)
                  cpValue[k].type = val
                  onChange(cpValue)
                }} style={{ width: 300 }} options={WORKFLOW_TYPES} value={v.type}></Select>
              </span>
            </div>
            <div style={{ display: v.type === "LLM_IMAGE" ? "flex" : "none", alignItems: "center", marginBottom: 12 }}>
              <span>提示词：</span>
              <span style={{ flex: 9 }}><VarInputTextArea index={k} vars={vars} onChange={e => {
                const cpValue = cloneDeep(value)
                cpValue[k].img_prompt = e.target.value
                onChange(cpValue)
              }} rows={10} value={v.img_prompt} /></span>
            </div>
            <div style={{ display: v.type.indexOf("TAVILY_") !== -1 || v.type.indexOf("BING_") !== -1 ? "flex" : "none", alignItems: "center", marginBottom: 12 }}>
              <span>搜索词：</span>
              <span style={{ flex: 9 }}><VarInputTextArea index={k} vars={vars} onChange={e => {
                const cpValue = cloneDeep(value)
                cpValue[k].search_txt = e.target.value
                onChange(cpValue)
              }} rows={1} value={v.search_txt} /></span>
            </div>
            <div style={{ display: v.type.indexOf("TAVILY_") !== -1 || v.type.indexOf("BING_") !== -1 ? "flex" : "none", alignItems: "center", marginBottom: 12 }}>
              <span>返回长度：</span>
              <span style={{ flex: 9 }}><InputNumber defaultValue={1} min={1} max={10} onChange={e => {
                const cpValue = cloneDeep(value)
                cpValue[k].search_result_num = e
                onChange(cpValue)
              }} value={v.search_result_num} /></span>
            </div>
            <div style={{ display: v.type === "LLM" || "LLM_IMAGE" ? "flex" : "none", alignItems: "center", marginBottom: 12 }}>
              <span>调度：</span>
              <span>
                <Select onChange={val => {
                  const cpValue = cloneDeep(value)
                  cpValue[k].dispatch = val
                  onChange(cpValue)
                }} style={{ width: 300 }} options={channels} value={v.dispatch}></Select>
              </span>
            </div>
            <div style={{ display: v.type === "LLM" ? "flex" : "none", alignItems: "center", marginBottom: 12 }}>
              <span>系统模板：</span>
              <span style={{ flex: 9 }}><VarInputTextArea index={k} vars={vars} onChange={e => {
                const cpValue = cloneDeep(value)
                cpValue[k].system_prompt = e.target.value
                onChange(cpValue)
              }} rows={10} value={v.system_prompt} /></span>
            </div>
            <div style={{ display: v.type === "LLM" ? "flex" : "none", alignItems: "center", marginBottom: 12 }}>
              <span>用户模板：</span>
              <span style={{ flex: 9 }}><VarInputTextArea index={k} vars={vars} onChange={e => {
                const cpValue = cloneDeep(value)
                cpValue[k].user_prompt = e.target.value
                onChange(cpValue)
              }} rows={10} value={v.user_prompt} /></span>
            </div>
          </div>
        ))
      }
      <Button type="link" onClick={() => {
        const cpValue = cloneDeep(value)
        cpValue.push({
          "user_prompt": "",
          "system_prompt": "",
          "name": "",
          "dispatch": "",
          "type": ""
        })
        onChange(cpValue)
      }}>新增step</Button>
    </div>
  )
}

const Index = () => {
  const [update, setUpdate] = useState(new Date().getTime())
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [channels, setChannels] = useState([])
  const n = useNavigate()
  const [data, setData] = useState({})


  const loadData = async (s) => {
    try {
      const chans = await http.get(apis.LLM_DISPATCH_LIST, { svr: '' })
      setChannels(pre => {
        const cpPre = cloneDeep(pre)
        for (let i = 0; i < chans.length; i++) {
          const item = chans[i]
          cpPre.push({
            label: item.name,
            value: item.role,
          })
        }
        return cpPre
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    loadData().then(() => {
      const params = new URLSearchParams(window.location.search)
      const urlId = params.get("id")
      if (urlId) {
        const data = JSON.parse(sessionStorage.getItem(`llmworkflow${urlId}`))
        data.data_json = JSON.parse(data.data_json) || []
        // data.vars = JSON.parse(data.vars)
        form.setFieldsValue(data)
        console.log(data)
        setData(data)
      }
    })



    // eslint-disable-next-line
  }, [])


  return (
    <Spin spinning={loading}>
      <div style={{ height: "calc(100vh - 230px)", overflow: "auto" }}>
        <span style={{ display: "none" }}>{update}</span>
        <Form form={form} autoComplete="off" onFinish={async v => {
          setLoading(true)
          const params = new URLSearchParams(window.location.search)
          const urlId = params.get("id")
          try {
            await http.post(apis.LLM_WORKFLOW_UPDATE, {
              id: parseInt(urlId),
              name: v.name,
              data_json: JSON.stringify(v.data_json)
            })
            n("/llm/workflow")
            message.success("成功")
          } catch (error) {

          }
          setLoading(false)
        }}>
          <Form.Item name="name" label="名称" rules={[{ required: true }]}>
            <Input placeholder="名称" />
          </Form.Item>
          <Form.Item name="data_json" label="配置" rules={[{ required: true }]}>
            <Item channels={channels || []} vars={data.vars || []} />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">保存</Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  )
}

export default Index