import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Form, Input, message, Button, Row, Col, Card, Select, InputNumber, Modal } from 'antd';
import { cloneDeep } from "lodash"
import { FUNS } from '../../utils/define'
import { sleep } from '../../utils'


const Index = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState([])
  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(new Date().getTime())
  const [form] = Form.useForm()
  const [channels, setChannels] = useState({
    "TXT": [],
    "VOICE": [],
    "IMAGE": []
  })
  const [search, setSearch] = useState({
    page: 1,
    page_size: 20
  })

  const loadData = async (s) => {
    try {
      const chans = await http.post(apis.LLM_CHANNEL_LIST, { svr: '' })
      setChannels(pre => {
        const cpPre = cloneDeep(pre)
        for (let i in cpPre) {
          cpPre[i] = handleChannels(chans, i)
          const selects = []
          for (let j = 0; j < cpPre[i].length; j++) {
            const item = cpPre[i][j]
            selects.push({
              label: item.name,
              value: item.id,
            })
          }
          cpPre[i] = selects
        }
        return cpPre
      })
      let data = await http.get(`${apis.LLM_DISPATCH_LIST}`, s)
      setResponse(data)

    } catch (error) {

    }
  }



  const handleChannels = (channel = [], modelType = "TXT") => {
    const arr = []
    for (let i = 0; i < channel.length; i++) {
      const item = channel[i]
      if (item.model_type === modelType) {
        arr.push(item)
      }
    }
    return arr
  }

  useEffect(() => {
    setLoading(true)

    loadData(search).then(() => {
      setLoading(false)
    })

    let onresize = () => {
      setUpdate(new Date().getTime())
    }

    window.addEventListener("resize", onresize)
    return () => {
      window.removeEventListener("resize", onresize)
    }

    // eslint-disable-next-line
  }, [])


  return (
    <>
      <Modal
        forceRender
        title="新增/编辑"
        open={open}
        okButtonProps={{ loading }}
        onOk={async () => {
          setLoading(true)
          try {
            const values = await form.validateFields()
            if (values.role) {
              await http.post(`${apis.LLM_DISPATCH_UPDATE}?role=${values.role}`, {
                name: values.name
              })
            } else {
              await http.post(apis.LLM_DISPATCH_CREATE, {
                name: values.name
              })
            }
            await loadData(search)
            message.success("成功")
            setOpen(false)
          } catch (error) {

          }
          setLoading(false)
        }}
        onCancel={() => setOpen(false)}
      >
        <Form form={form}>
          <Form.Item hidden name="role">
            <Input />
          </Form.Item>
          <Form.Item name="name" label="名称">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={() => {
        form.resetFields()
        setOpen(true)
      }}>新增</Button>
      <div style={{ height: 20 }}></div>
      <div style={{ display: "flex", flexWrap: "wrap", height: "calc(100vh - 280px)", overflow: "auto" }}>
        {
          response.map((v, k) => (
            <Card key={v.role} extra={<Button.Group>
              <Button onClick={() => {
                form.resetFields()
                form.setFieldsValue(v)
                setOpen(true)
              }} type="link">编辑</Button>
              <Button onClick={() => {
                setResponse(pre => {
                  const cpPre = cloneDeep(pre)
                  const obj = { ...cpPre[k].ws[0] }
                  cpPre[k].ws.push(obj)
                  return cpPre
                })
              }} type="link">添加渠道</Button>
            </Button.Group>} title={v.name} style={{ width: "calc(50% - 24px)", margin: 12 }} bordered={false}>
              {
                v.ws.map((v2, k2) => (
                  <div key={k2} style={{ display: "flex", marginBottom: 12, alignItems: "center" }}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>渠道：</span>
                      <Select value={v2.id} options={channels[v2.model_type]} onChange={val => {
                        setResponse(pre => {
                          const cpPre = cloneDeep(pre)
                          cpPre[k].ws[k2].id = val
                          return cpPre
                        })
                      }} />
                    </span>
                    <span style={{ display: "flex", alignItems: "center", marginLeft: 12 }}><span>权重：</span><InputNumber value={v2.weight} min={1} onChange={val => {
                      setResponse(pre => {
                        const cpPre = cloneDeep(pre)
                        cpPre[k].ws[k2].weight = val
                        return cpPre
                      })
                    }} /></span>
                    <Button danger type="link" disabled={v.ws.length === 1} onClick={() => {
                      setResponse(pre => {
                        const cpPre = cloneDeep(pre)
                        cpPre[k].ws.splice(k2, 1)
                        return cpPre
                      })
                    }}>删除</Button>
                  </div>
                ))
              }
              <Button type="primary" onClick={async () => {
                try {
                  const obj = {}
                  for (let i = 0; i < v.ws.length; i++) {
                    obj[v.ws[i].id.toString()] = v.ws[i].weight
                  }
                  await http.post(`${apis.LLM_DISPATCH_UPDATE}?role=${v.role}`, {
                    channel_weight: JSON.stringify(obj)
                  })
                  message.success("保存成功")
                } catch (error) {

                }
              }}>保存</Button>
            </Card>
          ))
        }
      </div>
    </>

  )
}

export default Index